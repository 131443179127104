import { ResourceTypes } from '../resources.d';

export const config = {
  failureRetries: 3,
  autoplay: {
    stopOnWinExceedsMinValue: 0,
    stopOnBalanceIncreaseMinValue: 0,
    stopOnBalanceDecreaseMinValue: 0,
    stopOnWinExceedsMaxValue: 500,
    stopOnBalanceIncreaseMaxValue: 5000,
    stopOnBalanceDecreaseMaxValue: 1000,
    defaultValues: {
      isStopOnAnyWin: false,
      isStopOnWinExceeds: false,
      isStopOnBalanceIncrease: false,
      isStopOnBalanceDecrease: false,
      singleWinValue: 25,
      increaseValue: 25,
      decreaseValue: 25,
    },
    autoSpinValues: [25, 50, 75, 100, 500],
    timeOut: 1000,
  },
  betSettings: {
    mockedBets: [20, 50, 75, 100],
    minBet: 20,
  },
  historyTable: {
    showBy: 20,
    head: {
      date: 'date',
      bet: 'bet',
      win: 'win',
      gameId: 'Game ID ',
    },
  },
  payInfoData: [
    {
      slug: ResourceTypes.scatter,
      combos: [],
    },
    {
      slug: ResourceTypes.wild,
      combos: [
        { pattern: 'x5', multiplier: 500 },
        { pattern: 'x4', multiplier: 250 },
        { pattern: 'x3', multiplier: 100 },
      ],
    },
    {
      slug: ResourceTypes.high5,
      combos: [
        { pattern: 'x5', multiplier: 500 },
        { pattern: 'x4', multiplier: 250 },
        { pattern: 'x3', multiplier: 100 },
      ],
    },
    {
      slug: ResourceTypes.high1,
      combos: [
        { pattern: 'x5', multiplier: 500 },
        { pattern: 'x4', multiplier: 250 },
        { pattern: 'x3', multiplier: 100 },
      ],
    },
    {
      slug: ResourceTypes.high2,
      combos: [
        { pattern: 'x5', multiplier: 250 },
        { pattern: 'x4', multiplier: 100 },
        { pattern: 'x3', multiplier: 50 },
      ],
    },
    {
      slug: ResourceTypes.high3,
      combos: [
        { pattern: 'x5', multiplier: 100 },
        { pattern: 'x4', multiplier: 50 },
        { pattern: 'x3', multiplier: 25 },
      ],
    },
    {
      slug: ResourceTypes.high4,
      combos: [
        { pattern: 'x5', multiplier: 50 },
        { pattern: 'x4', multiplier: 25 },
        { pattern: 'x3', multiplier: 10 },
      ],
    },
    {
      slug: ResourceTypes.low4,
      combos: [
        { pattern: 'x5', multiplier: 25 },
        { pattern: 'x4', multiplier: 10 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.low2,
      combos: [
        { pattern: 'x5', multiplier: 25 },
        { pattern: 'x4', multiplier: 10 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.low1,
      combos: [
        { pattern: 'x5', multiplier: 25 },
        { pattern: 'x4', multiplier: 10 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.low3,
      combos: [
        { pattern: 'x5', multiplier: 25 },
        { pattern: 'x4', multiplier: 10 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.low5,
      combos: [
        { pattern: 'x5', multiplier: 25 },
        { pattern: 'x4', multiplier: 10 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
  ],
};

export enum SlotId {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  WL = 'WL',
  SC1 = 'SC1',
  SC = 'SC',
  // MS1 = 'MS1',
}
