import i18n from 'i18next';
import _ from 'lodash';
import { Container, Graphics } from 'pixi.js';
import { formatNumber } from '@money.energy/utils-fe';
import { Colors, SlotId } from '../../config';
import { EventTypes } from '../../global.d';
import { setBetAmount, setCoinAmount, setCurrency } from '../../gql/cache';
import { normalizeCoins, showCurrency } from '../../utils';
import { TextField } from '../components/TextField';
import { eventEmitter, miniPayInfoAdditionalTextStyle, miniPayInfoStyle, SLOT_HEIGHT, SLOT_WIDTH } from '../config';
import { type Combos, type Icon, type IconCombo } from '../d';

// Here we will have other special symbols (Collect, Scatter, Money, Wild)
type SpecialSymbolType = 'WL' | 'SC1';

class MiniPayInfo extends Container {
  private id: number;

  private currency = '';

  private betAmount: number;

  public iconId: SlotId;

  public combos: Combos;

  public rect!: Graphics;

  private wrap!: Container;

  public multipliers!: TextField;

  public additionalText!: TextField;

  public specialSymbolDetails: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [key in SpecialSymbolType]: string;
  } = {
    WL: 'infoPayInfoWildText',
    SC1: 'infoPayInfoScatterText',
  };

  constructor(id: number, icon: Icon, combos: Combos) {
    super();
    this.id = id;
    this.iconId = icon.id;
    this.visible = false;
    this.sortableChildren = true;
    this.combos = _.cloneDeep(combos)?.reverse();
    this.betAmount = setBetAmount();
    this.currency = setCurrency();
    this.initRect();
    this.addChild(this.rect);
    this.initWrap();
    this.addChild(this.wrap);
    this.initMultipliers();
    this.wrap.addChild(this.multipliers.getText());
    this.initAdditionalText();
    this.wrap.addChild(this.additionalText.getText());
    this.setPayInfoData(icon.id, combos);

    eventEmitter.addListener(EventTypes.SHOW_PAY_INFO, (i: number) => {
      this.showPayInfo(i);
    });
    eventEmitter.addListener(EventTypes.DISABLE_ALL_MINI_PAY_INFOS, () => (this.visible = false));
    eventEmitter.addListener(EventTypes.START_SPIN_ANIMATION, () => (this.visible = false));
    eventEmitter.addListener(EventTypes.HANDLE_CHANGE_BET_AMOUNT, () => this.handleChangeBetAmount());
  }

  initRect(): void {
    this.rect = new Graphics();
    this.rect.beginFill(0x000000);
    this.rect.alpha = 0.6;
    this.rect.drawRoundedRect(0, 0, SLOT_WIDTH, SLOT_HEIGHT, 0);
  }

  initWrap(): void {
    this.wrap = new Container();
  }

  setPayInfoData(iconId: SlotId, combos: Combos): void {
    this.iconId = iconId;
    this.combos = combos;

    this.updatePayInfoData();
  }

  initMultipliers(): void {
    this.multipliers = new TextField('', SLOT_WIDTH, SLOT_HEIGHT, {}, true, {
      default: {
        ...miniPayInfoStyle,
        align: 'left',
        lineHeight: 50,
        fontSize: 35,
      },
      span: {
        fill: Colors.GAME_COLOR,
        lineHeight: 50,
      },
    });
    this.multipliers.text.anchor.set(0.5, 0);
  }

  private initAdditionalText(): void {
    this.additionalText = new TextField('', SLOT_WIDTH, 70, miniPayInfoAdditionalTextStyle);
    this.additionalText.text.anchor.set(0.5, 0);
  }

  private calcMultiplier(multiplier: number): number {
    return normalizeCoins(setCoinAmount() * multiplier);
  }

  public showPayInfo(uniqueId: number): void | undefined {
    if (uniqueId !== this.id) {
      this.visible = false;
      return;
    }
    if (this.iconId === SlotId.SC1) {
      this.additionalText.text.style = { ...miniPayInfoAdditionalTextStyle, fontSize: 40, lineHeight: 45 };
      this.additionalText.text.y = 0;
    } else {
      this.additionalText.text.y = this.multipliers.text.height;
      this.additionalText.text.style = { ...miniPayInfoAdditionalTextStyle, fontSize: 28, lineHeight: 30 };
    }
    this.visible = !this.visible;
  }

  private getCombos(): string {
    const combos =
      this.combos?.reduce(
        (acc: string, curr: IconCombo) =>
          `${acc} ${curr.pattern} <span>${formatNumber({
            currency: this.currency,
            value: this.calcMultiplier(curr.multiplier),
            showCurrency: showCurrency(this.currency),
          })}</span>\n`,
        '',
      ) || '';

    return combos.trimEnd();
  }

  private handleChangeBetAmount(): void {
    this.updatePayInfoData();
  }

  private updatePayInfoData(): void {
    this.multipliers.setText(this.getCombos());
    const specialSymbolText = this.getSpecialSymbolInfo();
    if (specialSymbolText) {
      this.additionalText.text.visible = true;
      this.additionalText.setText(i18n.t(specialSymbolText));
    } else {
      this.additionalText.text.visible = false;
      this.additionalText.setText('');
    }
    this.wrap.y = SLOT_HEIGHT / 2 - this.wrap.height / 2;
    this.wrap.x = SLOT_WIDTH / 2;
    if (this.iconId === SlotId.WL) {
      this.wrap.y = 15;
    } else if (this.iconId === SlotId.SC1) {
      this.wrap.y = 40;
    }
  }

  private getSpecialSymbolInfo(): string {
    const specialSymbolDetails = this.specialSymbolDetails[this.iconId as SpecialSymbolType];
    return specialSymbolDetails || '';
  }
}

export default MiniPayInfo;
